import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { Button as MuiButton, CircularProgress, styled } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateStatus, WorkerWithOrg } from '../../../types';
import { Worker } from '../../../types/Resource';

import { bindDascasToPlant } from '../../../apis/DascasApi';
import { bindDasCTagToWorker } from '../../../apis/DasCTagApi';
import { bindDasgasToWorker } from '../../../apis/DasgasApi';
import { bindDaslockToEquipment } from '../../../apis/DaslockApi';
import { bindDasloopToWorker } from '../../../apis/DasloopApi';
import { bindDaspowerToEquipment } from '../../../apis/DaspowerApi';
import { bindDastempToEquipment } from '../../../apis/DastempApi';
import { bindDastrackToPlant } from '../../../apis/DastrackApi';
import { bindDastrackVToVehicle } from '../../../apis/DastrackVApi';
import { bindDaswater } from '../../../apis/DaswaterApi';
import { useAppSelector } from '../../../hooks';

import ConfirmButtonDark from '../../../components/ConfirmButtonDark';
import SelectDialog from '../../../components/Dialog/SelectDialog';
import Search from '../../../components/Search';

import CompleteButtonSvgIcon from '../../../assets/SvgIcon/CompleteButtonSvgIcon';

import ItemBind, { DataItemBind } from './ItemBind';
import ItemBindSelected from './ItemBindSelected';

const MainContainer = styled('div')`
  padding: 0 20px;
  height: calc(100% - 128px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  background-color: ${({ theme }) => theme.color.secondary.$100};
  padding: 0 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContainerItem = styled('div')`
  flex: 1;
`;

const ContainerConfirm = styled('div')`
  display: flex;
  align-items: center;
  height: 80px;
`;

const ButtonDark = styled(MuiButton)`
  font-weight: 400;
  width: 120px;
  height: 32px;
  font-size: 14px;
  text-transform: none;
  color: white;
  background: ${({ theme }) => theme.color.secondary.$80};
  &:hover {
    background: ${({ theme }) => theme.color.secondary.$60};
  }
  &.Mui-disabled {
    opacity: 0.3;
    color: white;
    background-color: ${({ theme }) => theme.color.secondary.$80};
  }
`;

const handleTextStatus = (statusIcon) => {
  switch (statusIcon) {
    case 'loading':
      return <CircularProgress size={16} />;
    case 'success':
      return <CompleteButtonSvgIcon />;
    default:
      return <>OK</>;
  }
};

const ContainerSelected = styled('div')`
  margin-top: 10px;
  background-color: ${({ theme }) => theme.color.secondary.$100};
  border-radius: 4px;
`;

export interface DataDeviceOnProject {
  dasId: string;
  bindTo: string | null;
  deviceOwner: string;
  avatarDeviceOwner: string | undefined;
}

const ConfirmBind: React.VFC = () => {
  const {
    plants,
    vehicles,
    equipments,
    dasIdSelected,
    pipes,
    assignValue,
    modeDevice: mode,
  } = useAppSelector((store) => store.deviceManagementPage);
  const workerMap = useAppSelector(
    (store) => store.projects.resource.workerMap,
  );
  const orgMap = useAppSelector((store) => store.system.orgMap);

  const workers = useMemo<WorkerWithOrg[]>(() => {
    return Object.values(workerMap).map((r) => {
      const local = r as Worker;
      return {
        ...local,
        orgDetail: orgMap[local?.orgId ?? ''],
      };
    });
  }, [workerMap, orgMap]);

  const originDataItems = useRef<DataItemBind[] | null>(null);
  const { projectId } = useParams();

  const [textSearch, setTextSearch] = useState('');
  const [isEnableClose, setIsEnableClose] = useState(true);
  const [saveButtonStatus, setSaveButtonStatus] = useState<UpdateStatus>();

  const [dataItems, setDataItems] = useState<DataItemBind[]>([]);
  const [itemSelected, setItemSelected] = useState<DataItemBind>();
  const [isDisable, setDisable] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { mutateAsync: mutateBindDastrack } = useMutation(bindDastrackToPlant, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project-dastracks', projectId]);
    },
  });

  const { mutateAsync: mutateBindDascas } = useMutation(bindDascasToPlant, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project-dascases', projectId]);
    },
  });

  const { mutateAsync: mutateBindDasloop } = useMutation(bindDasloopToWorker, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project-dasloops', projectId]);
    },
  });

  const { mutateAsync: mutateBindDaspower } = useMutation(
    bindDaspowerToEquipment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['project-daspowers', projectId]);
      },
    },
  );

  const { mutateAsync: mutateBindDastemp } = useMutation(
    bindDastempToEquipment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['project-dastemps', projectId]);
      },
    },
  );

  const { mutateAsync: mutateBindDastrackV } = useMutation(
    bindDastrackVToVehicle,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['project-dastrack-Vs', projectId]);
      },
    },
  );

  const { mutateAsync: mutateBindDaswater } = useMutation(bindDaswater, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project-daswaters', projectId]);
    },
  });

  const { mutateAsync: mutateBindDaslock } = useMutation(
    bindDaslockToEquipment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['project-daslocks', projectId]);
      },
    },
  );

  const { mutateAsync: mutateBindDasgas } = useMutation(bindDasgasToWorker, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project-dasgas', projectId]);
    },
  });

  const { mutateAsync: mutateBindDasCTag } = useMutation(bindDasCTagToWorker, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project-dascollisiontags', projectId]);
    },
  });

  useEffect(() => {
    let originItems: DataItemBind[] = [];
    let selectedItem;
    switch (mode) {
      case 'dasgas':
      case 'dasloop':
      case 'das_collision_tag':
        originItems = workers.map((worker) => ({
          id: worker.id,
          name: worker.name,
          imageUrl: worker.imageURL,
          organization: worker.orgDetail?.name ?? '',
          color: worker.orgDetail?.color ?? '',
        }));
        break;
      case 'dastrack':
        originItems = plants
          .filter((plant) => plant.plantType === 'plant')
          .map((plant) => ({
            id: plant.id,
            name: plant.name,
            imageUrl: plant.imageURL,
            organization: plant.orgDetail?.name ?? '',
            color: plant.orgDetail?.color ?? '',
          }));
        break;
      case 'dascas':
        originItems = plants
          .filter((plant) => plant.plantType === 'crane')
          .map((plant) => ({
            id: plant.id,
            name: plant.name,
            imageUrl: plant.imageURL,
            organization: plant.orgDetail?.name ?? '',
            color: plant.orgDetail?.color ?? '',
          }));
        break;
      case 'dastrack-v':
        originItems = vehicles.map((vehicle) => ({
          id: vehicle.id,
          name: vehicle.name,
          imageUrl: vehicle.imageURL,
          organization: vehicle.orgDetail?.name ?? '',
          color: vehicle.orgDetail?.color ?? '',
        }));
        break;
      case 'daswater':
        originItems = pipes.map((pipe) => ({
          id: pipe.id,
          name: pipe.name,
          imageUrl: pipe.imageURL,
          organization: pipe.orgDetail?.name ?? '',
          color: pipe.orgDetail?.color ?? '',
        }));
        break;
      case 'dastemp':
      case 'daslock':
      case 'daspower':
        originItems = equipments.map((equipment) => ({
          id: equipment.id,
          name: equipment.name,
          imageUrl: equipment.imageURL,
          organization: equipment.orgDetail?.name ?? '',
          color: equipment.orgDetail?.color ?? '',
        }));
        break;
      default:
        break;
    }
    originDataItems.current = originItems;
    if (assignValue !== '' && assignValue !== null) {
      selectedItem = originItems.find((item) => item.id === assignValue);
      const filteredItems = originItems.filter(
        (item) => item.id !== assignValue,
      );
      setDataItems(filteredItems);
      setDisable(true);
    } else {
      setDataItems(originItems);
    }

    if (selectedItem) {
      setItemSelected(selectedItem);
    }
  }, [mode, assignValue]);

  const handleSearch = (e) => {
    const text = e.target.value;
    setTextSearch(text);
  };

  useEffect(() => {
    if (itemSelected) {
      setIsEnableClose(false);
    } else {
      setIsEnableClose(true);
    }
  }, [itemSelected]);

  const handleOnChange = (id: string) => {
    if (originDataItems.current) {
      const selectedData = originDataItems.current.find(
        (item) => item.id === id,
      );
      const filteredData = originDataItems.current.filter(
        (item) => item.id !== id,
      );
      setDataItems(filteredData);
      setItemSelected(selectedData);
      setDisable(true);
    }
  };

  const handleCancel = () => {
    if (originDataItems.current) {
      setDataItems(originDataItems.current);
      setDisable(false);
      setItemSelected(undefined);
    }
  };

  const handleSubmitAssign = async () => {
    if (!itemSelected) return;
    try {
      setSaveButtonStatus('loading');

      if (mode === 'dastrack') {
        await mutateBindDastrack({
          projectId: projectId ?? '',
          dasId: dasIdSelected,
          data: {
            plantId: itemSelected.id,
          },
        });
      }
      if (mode === 'dastrack-v') {
        await mutateBindDastrackV({
          projectId: projectId ?? '',
          dasId: dasIdSelected,
          vehicleId: itemSelected.id,
        });
      }
      if (mode === 'dasloop') {
        await mutateBindDasloop({
          projectId: projectId as string,
          dasId: dasIdSelected,
          workerId: itemSelected.id,
        });
      }
      if (mode === 'daspower') {
        await mutateBindDaspower({
          projectId: projectId as string,
          dasId: dasIdSelected,
          equipmentId: itemSelected.id,
        });
      }

      if (mode === 'dastemp') {
        await mutateBindDastemp({
          projectId: projectId as string,
          dasId: dasIdSelected,
          equipmentId: itemSelected.id,
        });
      }
      if (mode === 'daslock') {
        await mutateBindDaslock({
          projectId: projectId as string,
          dasId: dasIdSelected,
          equipmentId: itemSelected.id,
        });
      }
      if (mode === 'daswater') {
        await mutateBindDaswater({
          projectId: projectId as string,
          dasId: dasIdSelected,
          resourceId: itemSelected.id,
        });
      }
      if (mode === 'dasgas') {
        await mutateBindDasgas({
          projectId: projectId as string,
          dasId: dasIdSelected,
          workerId: itemSelected.id,
        });
      }
      if (mode === 'dascas') {
        await mutateBindDascas({
          projectId: projectId as string,
          dasId: dasIdSelected,
          data: {
            plantId: itemSelected.id,
          },
        });
      }
      if (mode === 'das_collision_tag') {
        await mutateBindDasCTag({
          projectId: projectId as string,
          dasId: dasIdSelected,
          workerId: itemSelected.id,
        });
      }

      let timer: NodeJS.Timeout;
      let timer2: NodeJS.Timeout;
      timer = setTimeout(() => {
        setSaveButtonStatus('success');
        timer2 = setTimeout(() => {
          setSaveButtonStatus(undefined);
          navigate(-1);
        }, 500);
      }, 2000);

      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (error) {
      setSaveButtonStatus(undefined);
    }
  };

  const items = dataItems.filter(
    (item) => item.name.indexOf(textSearch) !== -1,
  );

  return (
    <SelectDialog
      open
      onClose={() => navigate(-1)}
      title={t(`project-setting:page.devices.dialog.title.${mode}`)}
    >
      <MainContainer className="main-container">
        <ContainerContent>
          {itemSelected && (
            <ContainerSelected>
              <ItemBindSelected
                id={itemSelected.id}
                name={itemSelected.name}
                imageUrl={itemSelected.imageUrl}
                organization={itemSelected.organization}
                color={itemSelected.color}
                handleClick={handleCancel}
                // disabled={isDisable}
              />
            </ContainerSelected>
          )}
          <Search
            sx={{
              width: '100%',
              height: '32px',
              border: 'none',
              background: '#656565',
              marginBottom: '10px',
              marginTop: '10px',
              '& input': { color: 'white' },
            }}
            placeholder=""
            onChange={handleSearch}
            disabled={isDisable}
          />

          <ContainerItem data-cy="container-bind-item-device-management">
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className="List"
                  height={height}
                  itemCount={items.length}
                  itemSize={64}
                  width={width}
                  itemData={items.length === 0 ? undefined : items}
                >
                  {({ data, index, style }) => {
                    const item = data[index] as DataItemBind;
                    return (
                      <div style={style}>
                        <ItemBind
                          id={item.id}
                          name={item.name}
                          imageUrl={item.imageUrl}
                          organization={item.organization}
                          color={item.color}
                          onChangeSelected={handleOnChange}
                          isDisable={isDisable}
                        />
                      </div>
                    );
                  }}
                </List>
              )}
            </AutoSizer>
          </ContainerItem>
        </ContainerContent>

        <ContainerConfirm>
          <ButtonDark
            variant="text"
            onClick={() => navigate(-1)}
            data-cy="btn-cancel-bind-device-management"
          >
            {t('back')}
          </ButtonDark>
          <ConfirmButtonDark
            onClick={handleSubmitAssign}
            disabled={isEnableClose}
            status={saveButtonStatus}
            sx={{
              ml: 'auto',
              width: '120px',
              height: '32px',
            }}
            data-cy="btn-submit-bind-device-management"
          >
            {handleTextStatus(saveButtonStatus)}
          </ConfirmButtonDark>
        </ContainerConfirm>
      </MainContainer>
    </SelectDialog>
  );
};

export default ConfirmBind;
