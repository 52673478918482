import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  ChevronLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  VideoCameraFront,
} from '@mui/icons-material';

import { FeatureListItem, FeatureType } from '../../types';

import { useAppSelector } from '../../hooks';

import alertDashboard from '../../assets/images/svg/alert-dashboard.svg';
import areaManagementSvg from '../../assets/images/svg/area-management.svg';
import logSvg from '../../assets/images/svg/book-open.svg';
import cctvSvg from '../../assets/images/svg/cctv.svg';
import dasairSvg from '../../assets/images/svg/dasair.svg';
import dascasSvg from '../../assets/images/svg/dascasSvg.svg';
import dasconcreteSvg from '../../assets/images/svg/dasconcrete.svg';
import dasGasSvg from '../../assets/images/svg/dasgas.svg';
import dasloopSvg from '../../assets/images/svg/dasloop.svg';
import daspowerSvg from '../../assets/images/svg/daspower.svg';
import dastempSvg from '../../assets/images/svg/dastemp.svg';
import downloadSvg from '../../assets/images/svg/download.svg';
import gearSvg from '../../assets/images/svg/gear.svg';
import overviewSvg from '../../assets/images/svg/img_overview.svg';
import pipeSvg from '../../assets/images/svg/pipe.svg';
import plantSvg from '../../assets/images/svg/plant.svg';
import paSvg from '../../assets/images/svg/public-announcement.svg';
import reportSvg from '../../assets/images/svg/report_svg_icon.svg';
import tunnelSvg from '../../assets/images/svg/tunnel.svg';
import dastrackV from '../../assets/images/svg/vehicle.svg';
import AttendanceSvgIcon from '../../assets/SvgIcon/AttendanceSvgIcon';
import ConfinedSpaceSvgIcon from '../../assets/SvgIcon/ConfinedSpaceSvgIcon';
import DasLockSvgIcon from '../../assets/SvgIcon/DaslockSvgIcon';
import AiHostSvgIcon from '../../assets/SvgIcon/devices/AiHostSvgIcon';
import HomeSvgIcon from '../../assets/SvgIcon/HomeSvgIcon';
import SafieSvgIcon from '../../assets/SvgIcon/third-party/SafieSvgIcon';
import IconContainer from '../IconContainer';
import DarkTooltip from '../Toolip/DarkTooltip';

import { Divider } from '.';

interface BasicMenuProps {
  dataFeatures: { [key in FeatureType]: FeatureListItem } | undefined;
  projectId: string | undefined;
  selectedProjectId: string | null;
  enterDelay: number;
  overviewReadable: boolean;
  reportReadable: boolean;
  alertReadable: boolean;
  areaWriteable: boolean;
  PAreadable: boolean;
  topScroll: {
    posDown: boolean;
    posUp: boolean;
    handleDown: () => void;
    handleTop: () => void;
    setRef: any;
  };
  bottomScroll: {
    posDown: boolean;
    posUp: boolean;
    handleDown: () => void;
    handleTop: () => void;
    setRef: any;
  };
  onReset: () => void;
}

const BasicMenu: React.FC<BasicMenuProps> = ({
  dataFeatures,
  projectId,
  selectedProjectId,
  enterDelay,
  overviewReadable,
  reportReadable,
  alertReadable,
  areaWriteable,
  PAreadable,
  topScroll,
  bottomScroll,
  onReset,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar');
  const { role: userProjectRole } = useAppSelector((store) => store.projects);

  return (
    <nav>
      {/^\/dashboard.*/.test(location.pathname) && (
        <div
          className="back"
          onClick={() => {
            onReset();
            navigate('/project');
          }}
        >
          <div>
            <ChevronLeft />
          </div>
        </div>
      )}
      <NavLink
        className="home"
        to={`/dashboard/${selectedProjectId}`}
        data-cy="nav-sidebar-home"
      >
        <div>
          <HomeSvgIcon sx={{ width: '32px', height: '32px' }} />
        </div>
      </NavLink>
      <Divider />
      <div className="swtich-menu">
        {topScroll.posDown && (
          <IconContainer
            onClick={topScroll.handleTop}
            sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
          >
            <KeyboardArrowUp />
          </IconContainer>
        )}
        <div
          className="switch-item"
          ref={(ref) => {
            if (ref && ref.scrollHeight > ref.clientHeight) {
              topScroll.setRef(ref);
            }
          }}
        >
          {dataFeatures?.dasloop && (
            <NavLink
              to={`/dasloop-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasloop"
            >
              <DarkTooltip
                title={t('tooltip.dasloop-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dasloopSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.dastrack && (
            <NavLink
              to={`/dastrack-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dastrack"
            >
              <DarkTooltip
                title={t('tooltip.dastrack-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={plantSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.dascas && (
            <NavLink
              to={`/dascas-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dascas"
            >
              <DarkTooltip
                title={t('tooltip.dascas-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dascasSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.daspower && (
            <NavLink
              to={`/daspower-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-daspower"
            >
              <DarkTooltip
                title={t('tooltip.daspower-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={daspowerSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.dastemp && (
            <NavLink
              to={`/dastemp-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dastemp"
            >
              <DarkTooltip
                title={t('tooltip.dastemp-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dastempSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.dastrack_v && (
            <NavLink
              to={`/dastrack-v-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dastrack-v"
            >
              <DarkTooltip
                title={t('tooltip.dastrack-v-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dastrackV} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.dasair && (
            <NavLink
              to={`/dasair-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasair"
            >
              <DarkTooltip
                title={t('tooltip.dasair-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dasairSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.tunnel && (
            <NavLink
              to={`/tunnel-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-tunnel"
            >
              <DarkTooltip
                title={t('tooltip.tunnel')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={tunnelSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.attendance && (
            <NavLink
              to={`/multi-storey-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-attendance"
            >
              <DarkTooltip
                title={t('tooltip.multi-storey')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <AttendanceSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.dasgas && (
            <NavLink
              to={`/dasgas-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasgas"
            >
              <DarkTooltip
                title={t('tooltip.dasgas-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dasGasSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.daswater && (
            <NavLink
              to={`/daswater-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasair"
            >
              <DarkTooltip
                title={t('tooltip.daswater-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={pipeSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.cctv && (
            <NavLink
              to={`/cctv-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-cctv"
            >
              <DarkTooltip
                title={t('tooltip.cctv')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={cctvSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {(projectId === 'ff222130-8d6a-11ed-92b3-bba15f6c742a' ||
            projectId === '3ab4e2a0-0729-11ed-a223-6dabad045fcf') && (
            <NavLink
              to={`/dascart-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-cctv"
            >
              <DarkTooltip
                title="巡檢車"
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <VideoCameraFront />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.daslock && (
            <NavLink
              to={`/daslock-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-daslock"
            >
              <DarkTooltip
                title={t('tooltip.daslock')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <DasLockSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.dasconcrete && (
            <NavLink
              to={`/concrete-sensor-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-concrete-sensor"
            >
              <DarkTooltip
                title={t('tooltip.concrete-sensor')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dasconcreteSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.confined && (
            <NavLink
              to={`/confined-space-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasloop"
            >
              <DarkTooltip
                title={t('tooltip.ssss.ConfinedSpaceMonitoringSystem')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <ConfinedSpaceSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.das_ai_box && (
            <NavLink
              to={`/ai-camera/${selectedProjectId}`}
              data-cy="nav-sidebar-ai-camera"
            >
              <DarkTooltip
                title={t('ai-camera')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <AiHostSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.safie && (
            <NavLink
              to={`/safie-camera/${selectedProjectId}`}
              data-cy="nav-sidebar-safie-camera"
            >
              <DarkTooltip
                title={'Safie Camera'}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <SafieSvgIcon viewBox="0 0 52 52" width={23} height={26} />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
        </div>
        {topScroll.posUp && (
          <IconContainer
            onClick={topScroll.handleDown}
            sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
          >
            <KeyboardArrowDown />
          </IconContainer>
        )}
        <Divider />{' '}
        {bottomScroll.posDown && (
          <IconContainer
            onClick={bottomScroll.handleTop}
            sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
          >
            <KeyboardArrowUp />
          </IconContainer>
        )}
        <div
          className="switch-item"
          ref={(ref) => {
            if (ref && ref.scrollHeight > ref.clientHeight) {
              bottomScroll.setRef(ref);
            }
          }}
        >
          {overviewReadable && (
            <NavLink
              to={`/overview-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-overview"
            >
              <DarkTooltip
                title={t('tooltip.project-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={overviewSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {reportReadable && (
            <NavLink
              to={`/report-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-report"
            >
              <DarkTooltip
                title={t('tooltip.report')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={reportSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {alertReadable && (
            <NavLink
              to={`/alert-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-alert"
            >
              <DarkTooltip
                title={t('tooltip.alert')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={alertDashboard} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {PAreadable && (
            <NavLink
              to={`public-announcement/${selectedProjectId}`}
              data-cy="nav-sidebar-public-announcement"
            >
              <DarkTooltip
                title={t('tooltip.public-announcement')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={paSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {areaWriteable && (
            <NavLink
              to={`/area-management-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-area-management"
            >
              <DarkTooltip
                title={t('tooltip.area')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={areaManagementSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {(userProjectRole === 'owner' || userProjectRole === 'admin') &&
            dataFeatures?.report && (
              <NavLink
                to={`/data-download-dashboard/${selectedProjectId}`}
                data-cy="nav-sidebar-data-download"
              >
                <DarkTooltip
                  title={t('tooltip.data-download')}
                  placement="right"
                  enterDelay={enterDelay}
                >
                  <div>
                    <IconContainer className="icon">
                      <img src={downloadSvg} alt="" />
                    </IconContainer>
                  </div>
                </DarkTooltip>
              </NavLink>
            )}
        </div>
        {bottomScroll.posUp && (
          <IconContainer
            onClick={bottomScroll.handleDown}
            sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
          >
            <KeyboardArrowDown />
          </IconContainer>
        )}
      </div>
      <NavLink to={`/log/${selectedProjectId}`} data-cy="nav-sidebar-log">
        <DarkTooltip
          title={t('tooltip.log')}
          placement="right"
          enterDelay={enterDelay}
        >
          <div>
            <IconContainer className="icon">
              <img src={logSvg} alt="" />
            </IconContainer>
          </div>
        </DarkTooltip>
      </NavLink>
      <NavLink
        to={`/project-setting/${selectedProjectId}`}
        data-cy="nav-sidebar-project-setting"
        rel="noopener noreferrer"
      >
        <DarkTooltip
          title={t('tooltip.project-setting')}
          placement="right"
          enterDelay={enterDelay}
        >
          <div>
            <IconContainer className="icon">
              <img src={gearSvg} alt="" />
            </IconContainer>
          </div>
        </DarkTooltip>
      </NavLink>
    </nav>
  );
};

export default BasicMenu;
