import React from 'react';
import {
  Avatar as MuiAvatar,
  styled,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';

import { DeviceType } from '../../../types';

import { useAppSelector } from '../../../hooks';

import DefaultImage from '../../../assets/images/default-image/default_blank.jpg';
import DefaultEquipment from '../../../assets/images/default-image/equipment_default.png';
import DefaultPipe from '../../../assets/images/default-image/pipe_default.png';
import DefaultPlant from '../../../assets/images/default-image/plant_default.png';
import DefaultVehicle from '../../../assets/images/default-image/vehicle_default.png';
import DefaultWorker from '../../../assets/images/default-image/worker_default.png';
import OrgSvgIcon from '../../../assets/SvgIcon/OrgSvgIcon';

interface ContainerProps {
  disabled: boolean;
}
const Container = styled('div')<ContainerProps>`
  display: flex;
  height: 64px;
  align-items: center;
  transition: 0.3s;
  padding: 0 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ContainerDetail = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 12px;
`;

const Avatar = styled(MuiAvatar)`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  object-fit: cover;
`;

const AvatarTooltip = styled(MuiAvatar)`
  height: 160px;
  width: 160px;
  border-radius: 4px;
  object-fit: cover;
`;

const ContainerInfo = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const TextName = styled(Typography)`
  color: white;
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TextOrg = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  marginLeft: '6px',
  color: theme.color.secondary.$60,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const OrgStatus = styled('div')`
  width: 6px;
  height: 14px;
  border-radius: 3px;
`;

const ContainerOrg = styled('div')`
  display: flex;
  max-width: 15rem;
`;

const handleDefaultImage = (deviceType: DeviceType) => {
  switch (deviceType) {
    case 'dastrack':
    case 'dascas':
      return <Avatar src={DefaultPlant} alt="plant beeinventor" />;
    case 'dastrack-v':
      return <Avatar src={DefaultVehicle} alt="vehicle beeinventor" />;
    case 'dasloop':
    case 'dasgas':
    case 'das_collision_tag':
      return <Avatar src={DefaultWorker} alt="worker beeinventor" />;
    case 'daspower':
    case 'dastemp':
    case 'daslock':
      return <Avatar src={DefaultEquipment} alt="equipment beeinventor" />;
    case 'daswater':
      return <Avatar src={DefaultPipe} alt="pipe beeinventor" />;
    default:
      return <Avatar src={DefaultImage} alt="blank beeinventor" />;
  }
};

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.color.secondary.$100,
    boxShadow: `0px 0px 2px 2px ${theme.externalColor.dark.$20}`,
  },
}));

const ContainerTooltip = styled('div')`
  padding: 16px;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.secondary.$100};
`;

const ContainerDetailTooltip = styled('div')`
  display: flex;
  align-items: center;
  height: 24px;
  width: 100%;
`;

export interface DataItemBind {
  id: string;
  name: string;
  organization: string;
  imageUrl: string | null;
  color: string;
  onChangeSelected?: (id: string) => void;
  isDisable?: boolean;
}

const ItemBind: React.VFC<DataItemBind> = ({
  id,
  name,
  organization,
  imageUrl,
  color: colorOrg,
  onChangeSelected,
  isDisable,
}) => {
  const { modeDevice } = useAppSelector((store) => store.deviceManagementPage);
  const handleClick = () => {
    if (typeof onChangeSelected === 'function' && isDisable !== undefined) {
      if (!isDisable) {
        onChangeSelected(id);
      }
    }
  };
  return (
    <Tooltip
      title={
        true ? (
          ''
        ) : (
          <ContainerTooltip>
            <AvatarTooltip variant="square" src={imageUrl ?? ''}>
              {handleDefaultImage(modeDevice)}
            </AvatarTooltip>
            <Typography variant="h3" sx={{ fontWeight: 500, margin: '10px 0' }}>
              {name}
            </Typography>
            <ContainerDetailTooltip>
              <OrgSvgIcon sx={{ width: '24px', height: '24px' }} />
              <OrgStatus sx={{ backgroundColor: colorOrg ?? '#58B99E' }} />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, marginLeft: '10px' }}
              >
                {organization}
              </Typography>
            </ContainerDetailTooltip>
          </ContainerTooltip>
        )
      }
      placement="right-start"
    >
      <Container
        sx={{ opacity: isDisable ? '0.3' : 'none' }}
        onClick={handleClick}
        disabled={isDisable !== undefined ? isDisable : false}
        data-cy="bind-item-device-management"
      >
        <ContainerDetail>
          <Avatar variant="square" src={imageUrl ?? ''}>
            {handleDefaultImage(modeDevice ? modeDevice : 'dasloop')}
          </Avatar>
          <ContainerInfo>
            <TextName variant="body2" title={name}>
              {name}
            </TextName>
            <ContainerOrg>
              <OrgStatus sx={{ backgroundColor: colorOrg ?? '#58B99E' }} />
              <TextOrg>{organization}</TextOrg>
            </ContainerOrg>
          </ContainerInfo>
        </ContainerDetail>
      </Container>
    </Tooltip>
  );
};

export default ItemBind;
