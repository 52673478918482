import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  capitalize,
  CircularProgress,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  Coordinates,
  DeviceType,
  EnvironmentWillAssign,
  Group,
  PlantWillAssign,
  PolicyType,
  ResourceType,
  StatusType,
  StructureAssign,
  UpdateStatus,
  WorkerCertificateWillAssign,
  WorkerWillAssign,
} from '../../../types';
import { ResourceNavigator } from '../../../types/Resource';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import useFilteredGroups from '../../../hooks/useFilteredGroups';
import useHasPolicy from '../../../hooks/useHasPolicy';
import { setPinLocation } from '../../../slices/pages/projectPageSlice';

import CompleteButtonSvgIcon from '../../../assets/SvgIcon/CompleteButtonSvgIcon';
import mainTheme from '../../../theme';
import {
  navigatorEnvironmant,
  navigatorStructure,
  navigatorTowerCrane,
  navigatorWorker,
  phoneRegex,
} from '../../../utils/common';
import CheckListItem from '../../CheckListItem';
import ConfirmButton from '../../ConfirmButton';
import ManagementDialog from '../../Dialog/ManagementDialog';
import ManagementNoPermissionContent from '../../ManagementNoPermissionContent';
import AreaContent from '../edit-content/AreaContent';
import CertificateContent from '../edit-content/CertificateContent';
import ConnectedDevice from '../edit-content/ConnectedDeviceContent';
import ContactContent from '../edit-content/ContactEdit';
import EnvironmentBasicInformationContent from '../edit-content/EnvironmentBasicInformationContent';
import GroupEdit from '../edit-content/GroupEdit';
import StructureBasicInformationContent from '../edit-content/StructureBasicInformationContent';
import TowerCraneBasicInformationContent from '../edit-content/TowerCraneBasicInformationContent';
import WorkerBasicInformationContent from '../edit-content/WorkerBasicInformationContent';
import { checkStructureBasicInformationAvalible } from '../validation/concrete-validation';
import { checkCraneBasicInformationAvalible } from '../validation/crane-validation';
import {
  checkArea,
  checkEnvironmentBasicInformationAvailable,
} from '../validation/environment-validation';
import { checkhasAssignedDevice } from '../validation/management-validation';
import {
  checkWorkerBasicInformationAvailable,
  checkWorkerContactAvailable,
} from '../validation/worker-validation';

import {
  createCrane,
  createEnvironment,
  createStructure,
  createWorker,
} from './resourceOperations';

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  display: flex;
  flex: 1;
`;

const ContentWrapper = styled('div')`
  flex: 1;
  min-width: 340px;
  border-radius: 4px;
  display: flex;
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: 24px;
  height: 80px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 20px;
`;

const handleTextStatus = (t, statusIcon) => {
  switch (statusIcon) {
    case 'loading':
      return <CircularProgress size={16} />;
    case 'success':
      return <CompleteButtonSvgIcon />;
    default:
      return <>{t('save-and-close')}</>;
  }
};

interface TargetOperationDialogProps extends DialogProps {
  resourceType: ResourceType;
  title: string;
  groups: Array<Group> | undefined;
  selectedGroup: Group | undefined;
  onCloseDialog: () => void;
  onSuccessCreateResource: () => void;
  onSuccessBind?: () => void;
}
const TargetOperationDialog: React.FC<TargetOperationDialogProps> = ({
  open,
  resourceType,
  title,
  groups,
  selectedGroup,
  onCloseDialog,
  onSuccessCreateResource,
  onSuccessBind,
}) => {
  const projectId = useParams().projectId;
  const { policies, role: userProjectRole } = useAppSelector(
    (store) => store.projects,
  );

  let navigator: ResourceNavigator | undefined;

  switch (resourceType) {
    case 'worker':
      navigator = navigatorWorker;
      break;
    case 'environment':
      navigator = navigatorEnvironmant;
      break;
    case 'crane':
      navigator = navigatorTowerCrane;
      break;
    case 'structure':
      navigator = navigatorStructure;
      break;
  }
  const dispatch = useAppDispatch();

  const { t } = useTranslation('project-setting');
  const [indexNavigator, setIndexNavigator] = useState<number>(0);
  const [checkedValue, setCheckedValue] = useState(
    new Array(navigator?.length ?? 0).fill(false),
  );
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>();
  const [groupIdsWillAssign, setGroupIdsWillAssign] = useState<Array<string>>(
    [],
  );
  const [basicInformationWillAssign, setBasicInformationWillAssign] =
    useState<any>();
  const [contactIds, setContactIds] = useState<string[]>([]);
  const [areaId, setAreaId] = useState<string>();
  const [] = useState();
  const [workerCertificateWillAssign, setWorkerCertificateWillAssign] =
    useState<Array<WorkerCertificateWillAssign>>([]);
  const [devicesWillAssign, setDevicesWillAssign] = useState<
    Array<{
      id: string;
      type: DeviceType;
      dasId: string;
      location?: Coordinates | null;
    }>
  >([]);
  const [status, setStatus] = useState<StatusType>('default');
  const [message, setMessage] = useState('');
  const hasBindDevicePolicy = useHasPolicy(
    policies,
    'WriteDeviceBinding',
    userProjectRole,
  );
  const queryClient = useQueryClient();
  let policyName: PolicyType = 'ReadOneWorkerGroup';
  if (resourceType === 'crane') {
    policyName = `WriteOnePlantGroup` as PolicyType;
  } else if (resourceType === 'structure') {
    policyName = 'WriteOneFieldGroup';
  } else {
    policyName = `WriteOne${capitalize(resourceType)}Group` as PolicyType;
  }

  const filteredGroups = useFilteredGroups(
    policyName,
    groups ?? [],
    userProjectRole,
    policies,
  );

  const devices = devicesWillAssign.map((device) => ({
    id: device.id,
    name: device.dasId,
    batteryLevel: 100,
    location: device.location,
  }));

  const navgatorChecks: boolean[] = (navigator ?? []).map((item) => {
    let checked: boolean = false;

    switch (item.name) {
      case 'group':
        checked = true;
        break;
      case 'worker-basic-information':
        checked = checkWorkerBasicInformationAvailable(
          basicInformationWillAssign,
        );
        break;
      case 'environment-basic-information':
        checked = checkEnvironmentBasicInformationAvailable(
          basicInformationWillAssign,
        );
        break;
      case 'tower-crane-basic-information':
        checked = checkCraneBasicInformationAvalible(
          basicInformationWillAssign,
        );
        break;
      case 'structure-basic-information':
        checked = checkStructureBasicInformationAvalible(
          basicInformationWillAssign,
        );
        break;
      case 'emergency-contact':
        checked = checkWorkerContactAvailable(contactIds);
        break;
      case 'area':
        checked = checkArea(areaId);
        break;
      case 'connected-device':
        checked = checkhasAssignedDevice(devicesWillAssign);
        break;
    }

    return checked;
  });

  const saveable = () => {
    return navigator?.reduce((prev, curr, index) => {
      if (!curr.required) return prev && true;
      return prev && navgatorChecks[index];
    }, true);
  };

  const handleCertificateWillAssign = (
    workerCertificate: Array<WorkerCertificateWillAssign>,
  ) => {
    setWorkerCertificateWillAssign(workerCertificate);
  };

  useEffect(() => {
    const defaultGroups: string[] = [];
    if (selectedGroup) {
      defaultGroups.push(selectedGroup.id);
    }

    setGroupIdsWillAssign(defaultGroups);
  }, [open, groups, selectedGroup]);

  useEffect(() => {
    const updateChecked = checkedValue.map((item, index) =>
      index === indexNavigator ? true : false,
    );
    setCheckedValue(updateChecked);
  }, [indexNavigator]);

  const next = () => {
    setIndexNavigator(indexNavigator + 1);
  };

  const previous = () => {
    setIndexNavigator(indexNavigator - 1);
  };

  const enablePrevious = useMemo(() => {
    if (indexNavigator > 0) {
      return true;
    } else {
      return false;
    }
  }, [indexNavigator]);

  const enableNext = useMemo(() => {
    if (indexNavigator < (navigator?.length ?? 0) - 1) {
      return true;
    } else {
      return false;
    }
  }, [indexNavigator]);

  const handleCloseEditDialog = () => {
    onCloseDialog();
    setBasicInformationWillAssign(undefined);
    setContactIds([]);
    dispatch(setPinLocation([0, 0]));
    setWorkerCertificateWillAssign([]);
    setGroupIdsWillAssign([]);
    setDevicesWillAssign([]);
    setStatus('default');
    setMessage('');
  };

  const handleSubmitCreateResource = async () => {
    try {
      let dataWillAssign: any;
      if (projectId && resourceType === 'worker') {
        dataWillAssign = basicInformationWillAssign as WorkerWillAssign;
        let fullPhone: string | undefined;
        const phoneResult = phoneRegex.exec(dataWillAssign.phone);
        if (phoneResult && phoneResult.length >= 3 && phoneResult[2] === '') {
          fullPhone = undefined;
        } else if (!phoneResult) {
          fullPhone = undefined;
        } else {
          fullPhone = dataWillAssign.phone;
        }
        setUpdateStatus('loading');
        setStatus('default');
        await createWorker({
          projectId,
          basicInformation: {
            name: dataWillAssign.name,
            gender: dataWillAssign.gender,
            birthday: dataWillAssign.birthday,
            email: dataWillAssign.email,
            phone: fullPhone,
            division: dataWillAssign.division,
            trade: dataWillAssign.trade,
            registrationNumber: dataWillAssign.registrationNumber,
            groupIds: groupIdsWillAssign ?? [],
            remark: dataWillAssign.remark,
            orgId: dataWillAssign.orgId,
            type: 'worker',
            bindingDasAoaTags: [],
            bindingDasCollisionTags: [],
          },
          couldBindDevice: hasBindDevicePolicy,
          devices: devicesWillAssign,
          contactIds: contactIds,
          certificateData: workerCertificateWillAssign,
          avatar: dataWillAssign.avatarFile,
        });
      } else if (projectId && resourceType === 'environment') {
        setUpdateStatus('loading');
        setStatus('default');
        dataWillAssign = basicInformationWillAssign as EnvironmentWillAssign;
        await createEnvironment({
          projectId,
          basicInformation: {
            name: dataWillAssign.name,
            groupIds: groupIdsWillAssign ?? [],
            type: 'environment',
            orgId: dataWillAssign.orgId,
            remark: dataWillAssign.remark,
            areaId: areaId ?? '',
          },
          couldBindDevice: hasBindDevicePolicy,
          devices: devicesWillAssign,
          contactIds: contactIds,
          avatar: dataWillAssign.avatarFile,
        });
      } else if (projectId && resourceType === 'structure') {
        setUpdateStatus('loading');
        setStatus('default');
        dataWillAssign = basicInformationWillAssign as StructureAssign;

        await createStructure({
          projectId: projectId as string,
          basicInformation: {
            name: dataWillAssign.name,
            groupIds: groupIdsWillAssign ?? [],
            fieldType: dataWillAssign.fieldType,
            materialType: dataWillAssign.materialType,
            size: dataWillAssign.size ?? undefined,
            deviceCad: dataWillAssign.deviceCad ?? undefined,
            deviceCadPosition: dataWillAssign.deviceCadPosition ?? undefined,
            strengthTarget: dataWillAssign.strengthTarget,
            location: dataWillAssign.location
              ? {
                  ...dataWillAssign.location,
                  alt: dataWillAssign.location.alt
                    ? Number(dataWillAssign.location.alt)
                    : null,
                }
              : undefined,
            startTime: dataWillAssign.startTime,
            endTime: dataWillAssign.endTime,
            fc28: dataWillAssign.fc28,
            settingAngle: dataWillAssign.settingAngle,
            alertAngle: dataWillAssign.alertAngle,
            orgId: dataWillAssign.orgId,
            remark: dataWillAssign.remark,
            bindingDevicesLocation: dataWillAssign.bindingDevicesLocation,
          },
          contactIds: contactIds,
          couldBindDevice: hasBindDevicePolicy,
          devices: devicesWillAssign,
          avatar: dataWillAssign.avatarFile,
        });
      } else if (
        projectId &&
        (resourceType === 'crane' || resourceType === 'plant')
      ) {
        setUpdateStatus('loading');
        setStatus('default');
        dataWillAssign = basicInformationWillAssign as PlantWillAssign;
        await createCrane({
          projectId: projectId as string,
          basicInformation: {
            type: 'crane',
            orgId: dataWillAssign.orgId,
            name: dataWillAssign.name,
            model: dataWillAssign.model,
            serialNumber: dataWillAssign.serialNumber,
            permitNumber: dataWillAssign.permitNumber,
            permitIssuer: dataWillAssign.permitIssuer,
            permitExpiryDate: dataWillAssign.permitExpiryDate,
            safetyLoad: dataWillAssign.safetyLoad ?? undefined,
            yearOfProduction: dataWillAssign.yearOfProduction ?? undefined,
            brand: dataWillAssign.brand ?? undefined,
            numberReading: dataWillAssign.numberReading ?? undefined,
            remark: dataWillAssign.remark ? dataWillAssign.remark : undefined,
            groupIds: groupIdsWillAssign ?? [],
            plantType: dataWillAssign.plantType,
            location: dataWillAssign.location ?? undefined,
          },
          couldBindDevice: hasBindDevicePolicy,
          devices: devicesWillAssign,
          contactIds: contactIds,
          avatar: dataWillAssign.avatarFile,
        });
      }

      let timer: NodeJS.Timeout;
      let timer2: NodeJS.Timeout;
      timer = setTimeout(() => {
        setUpdateStatus('success');
        setMessage(t('createSuccess'));
        setStatus('success');
        onSuccessCreateResource();
        onSuccessBind?.();
        queryClient.invalidateQueries([
          'project-contacts',
          projectId as string,
        ]);

        timer2 = setTimeout(() => {
          handleCloseEditDialog();
          setUpdateStatus(undefined);
        }, 2000);
      }, 500);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (error) {
      setUpdateStatus(undefined);
      if (error instanceof Error) {
        setMessage(error.message);
      }
      setStatus('error');
    }
  };

  const handleOnChange = (value: any) => {
    const contactId = value;
    const isContain = contactIds.includes(contactId);
    if (!isContain) {
      setContactIds([...contactIds, contactId]);
    } else {
      setContactIds(contactIds.filter((item) => item !== contactId));
    }
  };

  const handleBasicInformationWillAssign = (data: any) => {
    setBasicInformationWillAssign(data);
  };

  return (
    <ManagementDialog
      open={open}
      onClose={handleCloseEditDialog}
      status={status}
      message={message}
      title={title}
    >
      <DialogContent>
        <CheckList>
          {navigator?.map((item, index) => {
            return (
              <CheckListItem
                key={item.id}
                name={t(`navigator.${item.name}`)}
                required={item.required}
                checked={navgatorChecks[index]}
                selected={checkedValue[index]}
                data-cy={`btn-list-navigator-${resourceType}-${item.name}`}
                onClick={() => setIndexNavigator(index)}
              />
            );
          })}
        </CheckList>
        <ContentWrapper className="content wrapper">
          {navigator?.[indexNavigator].name === 'group' && (
            <GroupEdit
              groupIdsSelected={groupIdsWillAssign}
              handleGroupAssign={(groupIds) => setGroupIdsWillAssign(groupIds)}
              listGroup={filteredGroups}
              resource={resourceType}
            />
          )}
          {navigator?.[indexNavigator].name === 'worker-basic-information' && (
            <WorkerBasicInformationContent
              handleWorkerDataWillAssign={handleBasicInformationWillAssign}
              data={basicInformationWillAssign}
            />
          )}
          {navigator?.[indexNavigator].name ===
            'environment-basic-information' && (
            <EnvironmentBasicInformationContent
              handleEnvironmentDataWillAssign={handleBasicInformationWillAssign}
              data={basicInformationWillAssign}
            />
          )}
          {navigator?.[indexNavigator].name ===
            'tower-crane-basic-information' && (
            <TowerCraneBasicInformationContent
              handlePlantDataWIllAssign={handleBasicInformationWillAssign}
              data={basicInformationWillAssign}
            />
          )}

          {navigator?.[indexNavigator].name ===
            'structure-basic-information' && (
            <StructureBasicInformationContent
              handleStructureAssign={handleBasicInformationWillAssign}
              data={basicInformationWillAssign}
            />
          )}

          {navigator?.[indexNavigator].name === 'emergency-contact' && (
            <ContactContent
              onChange={handleOnChange}
              listContact={contactIds}
            />
          )}
          {navigator?.[indexNavigator].name === 'certificate' && (
            <CertificateContent
              handleCertificateWillAssign={handleCertificateWillAssign}
              data={workerCertificateWillAssign}
            />
          )}
          {navigator?.[indexNavigator].name === 'connected-device' && (
            <>
              {hasBindDevicePolicy ? (
                <ConnectedDevice
                  projectId={projectId}
                  resourceType={resourceType}
                  data={devices}
                  handleDasIDWillAssign={(paramsDevices) => {
                    let deviceType: DeviceType | undefined;

                    switch (resourceType) {
                      case 'worker':
                        deviceType = 'dasloop';
                        break;
                      case 'crane':
                        deviceType = 'dascas';
                        break;
                      case 'structure':
                        deviceType = 'dasconcrete';
                        break;
                    }

                    if (deviceType) {
                      setDevicesWillAssign(
                        paramsDevices.map((device) => ({
                          id: device.id,
                          type: deviceType as DeviceType,
                          dasId: device.name,
                          location: device.location,
                        })),
                      );
                    }
                  }}
                />
              ) : (
                <ManagementNoPermissionContent />
              )}
            </>
          )}
          {navigator?.[indexNavigator].name === 'area' && (
            <AreaContent
              projectId={projectId}
              selectedAreaId={areaId}
              onChange={(id) => {
                setAreaId(id);
              }}
            />
          )}
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        {enablePrevious && (
          <DialogButton
            sx={{
              '&.Mui-disabled': {
                color: 'white',
              },
              '&:hover, &:active': {
                background: mainTheme.color.secondary.$60,
              },
              marginRight: 'auto',
            }}
            color="secondary"
            startIcon={<ChevronLeft />}
            onClick={previous}
          >
            {t('previous')}
          </DialogButton>
        )}
        {!enablePrevious && (
          <DialogButton
            sx={{
              '&.Mui-disabled': {
                color: 'white',
              },
              '&:hover, &:active': {
                background: mainTheme.color.secondary.$60,
              },
              margin: `0 ${enablePrevious ? '0' : 'auto'} 0 ${
                enablePrevious ? 'auto' : '0'
              }`,
            }}
            color="secondary"
            onClick={handleCloseEditDialog}
          >
            {t('cancel')}
          </DialogButton>
        )}
        {!enableNext && (
          <DialogButton
            sx={{
              '&.Mui-disabled': {
                color: 'white',
              },
              '&:hover, &:active': {
                background: mainTheme.color.secondary.$60,
              },
              margin: `0 ${enablePrevious ? '0' : 'auto'} 0 ${
                enablePrevious ? 'auto' : '0'
              }`,
            }}
            color="secondary"
            onClick={handleCloseEditDialog}
            data-cy="btn-cancel-add-new-worker"
          >
            {t('cancel')}
          </DialogButton>
        )}

        {enableNext ? (
          <DialogButton
            variant="contained"
            color="primary"
            disabled={false}
            onClick={next}
            endIcon={<ChevronRight />}
            data-cy={`btn-next-add-new-${resourceType}`}
          >
            {t('next')}
          </DialogButton>
        ) : (
          <ConfirmButton
            sx={{
              ml: 'auto',
              width: '120px',
              height: '32px',
              textTransform: 'none',
            }}
            disabled={!saveable()}
            status={updateStatus}
            onClick={handleSubmitCreateResource}
            data-cy={`btn-submit-add-new-${resourceType}`}
          >
            {handleTextStatus(t, updateStatus)}
          </ConfirmButton>
        )}
      </DialogActions>
    </ManagementDialog>
  );
};

export default TargetOperationDialog;
