import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import {
  ConnectedDeviceInfo,
  Contact,
  WorkerCertificateWillAssign,
  WorkerGroup,
  WorkerWillAssign,
} from '../../../../types';
import { DasCTag, Dasloop } from '../../../../types/Device';
import { Worker } from '../../../../types/Resource';

import { getProjectDasCTags } from '../../../../apis/DasCTagApi';
import {
  getProjectDasloops,
  getProjectWorkers,
  getWorkerCertificate,
} from '../../../../apis/DasloopApi';

import CheckListItem from '../../../../components/CheckListItem';
import Loading from '../../../../components/Loading';

import CleanDateSvgIcon from '../../../../assets/SvgIcon/CleanDateSvgIcon';
import EditSvgIcon from '../../../../assets/SvgIcon/EditSvgIcon';
import { navigatorWorker as navigator } from '../../../../utils/common';
import CertificateContentView from '../../content/CertificateContentView';
import ConnectedDeviceView from '../../content/ConnectedDeviceView';
import ContactView from '../../content/ContactView';
import GroupView from '../../content/GroupView';
import {
  checkWorkerBasicInformationAvailable,
  checkWorkerCertificate,
  checkWorkerContactAvailable,
  checkWorkerDasloopConnected,
} from '../../validation/worker-validation';

import BasicInformationContent from './BasicInformationContent';

const Dialog = styled(MuiDialog)`
  color: white;
  & .MuiDialog-paper {
    width: 676px;
    height: 686px;
    max-width: unset;
    background: ${({ theme }) => theme.color.secondary.$80};
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 8px 8px 16px;
  height: 48px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  ${({ theme }) => ({ ...theme.typography.h3 })}
  font-family: 'Montserrat';
  flex: 1;
  color: white;
  padding: 0;
`;

const ContentWrapper = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  @media only screen and (max-width: 600px) {
    min-height: 240px;
  }
`;

const ContainerSelected = styled('div')`
  width: 380px;
  border-radius: 4px;
  padding: 10px 20px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const IconButtonStyled = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: 0.3s;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

interface ViewWorkerProps extends DialogProps {
  projectId: string | undefined;
  title: string;
  navigatorIndex: number;
  workerSelected: Worker | undefined;
  contacts: Array<Contact>;
  workerGroupSelected: Array<WorkerGroup>;
  onCloseDialog: () => void;
  onClickEdit: () => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const ViewWorker: React.FC<ViewWorkerProps> = ({
  projectId,
  open,
  title,
  navigatorIndex,
  workerGroupSelected,
  contacts,
  onCloseDialog,
  onClickEdit,
  onSelectNavigatorIndex,
}) => {
  const [searchParams] = useSearchParams();
  const [checkedValue, setCheckedValue] = useState(
    new Array(navigator.length).fill(false),
  );
  const { t } = useTranslation('project-setting');
  const [workerDataWillAssign, setWorkerDataWillAssign] =
    useState<WorkerWillAssign>();
  const [workerCertificateWillAssign, setWorkerCertificateWillAssign] =
    useState<Array<WorkerCertificateWillAssign>>([]);

  const workerId = useMemo(() => {
    return searchParams.get('workerId');
  }, [searchParams]);

  const { data: worker } = useQuery(
    ['get-worker-by-id', projectId, workerId],
    async () => {
      const res = await getProjectWorkers({
        projectId: projectId as string,
        params: {
          id: workerId as string,
        },
      });

      if (res.data.data.length === 1) return res.data.data[0];
    },
    {
      enabled: !!projectId && !!workerId,
      refetchOnWindowFocus: false,
    },
  );

  const { data: assignedDasLoops } = useQuery(
    ['assigned-dasloops', projectId, worker?.id],
    async () => {
      let dasLoops: Dasloop[] = [];
      const bindingDasloops = worker?.bindingDasloops;
      if (bindingDasloops) {
        for (let i = 0; i < bindingDasloops.length; i++) {
          const res = await getProjectDasloops({
            projectId: projectId as string,
            params: {
              dasId: bindingDasloops[i],
              limit: 1,
            },
          });

          if (res.data.data.length === 1) {
            dasLoops.push(res.data.data[0]);
          }
        }
      }
      return dasLoops;
    },
    {
      enabled: !!projectId && !!worker?.id,
      initialData: [],
      refetchOnWindowFocus: false,
    },
  );

  const { data: assignedDasCTags } = useQuery(
    ['assigned-dasCTag', projectId, worker?.id],
    async () => {
      let dasCTags: DasCTag[] = [];
      const bindingDasCTags = worker?.bindingDasCollisionTags;
      if (bindingDasCTags) {
        for (let i = 0; i < bindingDasCTags.length; i++) {
          const res = await getProjectDasCTags({
            projectId: projectId as string,
            params: {
              dasId: bindingDasCTags[i],
              limit: 1,
            },
          });

          if (res.data.data.length === 1) {
            dasCTags.push(res.data.data[0]);
          }
        }
      }
      return dasCTags;
    },
    {
      enabled: !!projectId && !!worker,
      initialData: [],
      refetchOnWindowFocus: false,
    },
  );

  const { data: workerCertificates, isFetching: isFethingCertificates } =
    useQuery(
      ['get-worker-certificates', projectId, worker?.id],
      async () => {
        const res = await getWorkerCertificate({
          projectId: projectId as string,
          workerId: worker?.id as string,
        });

        return res.data.data;
      },
      { enabled: !!worker, refetchOnWindowFocus: false, retry: false },
    );

  useEffect(() => {
    setWorkerDataWillAssign({
      name: worker?.name ?? '',
      imageURL: worker?.imageURL ?? '',
      gender: worker?.gender as 'male' | 'female',
      birthday: worker?.birthday ?? '',
      email: worker?.email ?? '',
      phone: worker?.phone ?? '',
      division: worker?.division ?? '',
      trade: worker?.trade ?? '',
      registrationNumber: worker?.registrationNumber ?? undefined,
      groupIds: worker?.groupIds ?? [],
      remark: worker?.remark ?? '',
      orgId: worker?.orgId ?? '',
      id: worker?.id ?? '',
    });
  }, [worker]);

  useEffect(() => {
    setWorkerCertificateWillAssign(
      workerCertificates?.map((certificate) => ({
        name: certificate.name,
        number: certificate.number,
        expiryDate: certificate.expiryDate,
        imageURL: certificate.imageURL,
        id: certificate.id,
      })) ?? [],
    );
  }, [workerCertificates]);

  useEffect(() => {
    const updateChecked = checkedValue.map((item, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedValue(updateChecked);
  }, [navigatorIndex]);

  const workerConnectedDevice: Array<ConnectedDeviceInfo> = useMemo(() => {
    const dasLoopsInfo = assignedDasLoops.map((dasloop) => ({
      dasId: dasloop.dasId,
    }));

    const dasCTagsInfo = assignedDasCTags.map((tag) => ({
      dasId: tag.dasId,
    }));

    return [...dasLoopsInfo, ...dasCTagsInfo];
  }, [assignedDasLoops, assignedDasCTags]);

  const listConnected = useMemo(() => {
    const dasLoopInfo = assignedDasLoops.map((dasloop) => ({
      id: dasloop.id,
      name: dasloop.dasId,
      batteryLevel: dasloop.shadow?.dataPoint?.batteryLevel?.value ?? 0,
    }));

    const dasCTagInfo = assignedDasCTags.map((dasCTag) => ({
      id: dasCTag.id,
      name: dasCTag.dasId,
      batteryLevel: 0,
    }));

    return [...dasLoopInfo, ...dasCTagInfo];
  }, [assignedDasLoops, assignedDasCTags]);

  return (
    <Dialog open={open}>
      <Header>
        <DialogTitle>{title}</DialogTitle>
        <IconButtonStyled
          onClick={() => {
            onCloseDialog();
            onClickEdit();
          }}
          data-cy="dialog-edit-worker"
        >
          <EditSvgIcon
            sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          />
        </IconButtonStyled>
        <CleanDateSvgIcon
          sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          onClick={onCloseDialog}
        />
      </Header>
      {isFethingCertificates ? (
        <Loading />
      ) : (
        <>
          <ContentWrapper>
            <DialogContent>
              <CheckList>
                {navigator.map((item, index) => {
                  let checked: boolean = false;
                  switch (item.name) {
                    case 'group':
                      checked = true;
                      break;
                    case 'worker-basic-information':
                      checked =
                        checkWorkerBasicInformationAvailable(
                          workerDataWillAssign,
                        );
                      break;
                    case 'emergency-contact':
                      checked = checkWorkerContactAvailable(
                        contacts.map((cp) => cp.id),
                      );
                      break;
                    case 'certificate':
                      checked = checkWorkerCertificate(
                        workerCertificateWillAssign,
                      );
                      break;
                    case 'connected-device':
                      checked = checkWorkerDasloopConnected(listConnected);
                      break;
                  }

                  return (
                    <CheckListItem
                      key={item.id}
                      name={t(`navigator.${item.name}`)}
                      required={item.required}
                      checked={checked}
                      onClick={() => onSelectNavigatorIndex(index)}
                      selected={checkedValue[index]}
                    />
                  );
                })}
              </CheckList>
            </DialogContent>
            <ContainerSelected>
              {navigator[navigatorIndex].name === 'group' && (
                <GroupView group={workerGroupSelected} resource="worker" />
              )}
              {navigator[navigatorIndex].name ===
                'worker-basic-information' && (
                <BasicInformationContent data={worker} />
              )}
              {navigator[navigatorIndex].name === 'emergency-contact' && (
                <ContactView data={contacts} />
              )}
              {navigator[navigatorIndex].name === 'certificate' && (
                <CertificateContentView data={workerCertificates} />
              )}
              {navigator[navigatorIndex].name === 'connected-device' && (
                <ConnectedDeviceView
                  data={workerConnectedDevice}
                  resource="worker"
                />
              )}
            </ContainerSelected>
          </ContentWrapper>
        </>
      )}
    </Dialog>
  );
};

export default ViewWorker;
